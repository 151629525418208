.fade-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

sup { 
  font-size: 10px;
  vertical-align: top;
}