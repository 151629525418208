.flashGrid {
  position: fixed !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11000;

}
.flashMessage {
  margin-left: auto;
  margin-right: auto;
  /* margin: 0 auto !important; */
  /* width: 50% !important; */
}
